import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'gatsby'

// Generic
import Pill from '../Pill/Pill'

const ArticleGridFeaturedItem = (props) => {
  const { body, category, className, date, media, title, url } = props

  return (
    <div className={classNames('md:flex justify-end bg-white shadow-lg relative', className)}>
      <div className='md:absolute top-0 left-0 md:w-1/2 md:h-full bg-gray-light md:flex md:items-center'>
        {media}
      </div>
      <Link
        to={url}
        className='block md:w-1/2 py-8 md:py-16 lg:py-24 xl:py-32'
      >
        <div className='md:max-w-lg mx-auto px-8 md:px-4'>
          <Pill className='mb-4'>
            {category}
          </Pill>
          <p className='h3 mb-2'>{title}</p>
          <div className='mb-4 line-clamp-3'>
            <p>{body}</p>
          </div>
          {date && (
            <p className='text-xs font-medium tracking-wider uppercase text-midnight text-opacity-75'>{date}</p>
          )}
        </div>
      </Link>
    </div>
  )
}

ArticleGridFeaturedItem.propTypes = {
  body: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  className: PropTypes.string,
  date: PropTypes.string,
  media: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default ArticleGridFeaturedItem
