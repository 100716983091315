import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Content Components
import { RenderPositionContentComponents } from '../../elements/PageElements'

// Generic
import Container from '../../shared/Container/Container'
import Button from '../../shared/Button/Button'
import ArticleGrid from '../../shared/ArticleGrid/ArticleGrid'
import Pagination from '../../shared/Pagination/Pagination'

// Utils
import { urlMap } from '../../../utils/urls'

const CaseStudyTestimonialIndex = (props) => {
  const { slices, items, featuredItem, type, pageContext, totalCount } = props

  // Little bit hacky, but we can use this to drop the "featured" testimonial
  // in the middle
  const secondItems = items.splice(6)

  return (
    <>
      <RenderPositionContentComponents
        slices={slices}
        validComponents={['hero']}
      />
      <section id='content'>
        <Container>
          <div className='sm:flex justify-between mb-14 sm:mb-20'>
            <h2 className='mb-6 sm:mb-0 text-center sm:text-left'>Read our success stories</h2>
            <ul className='flex justify-center'>
              <li>
                <Button
                  url={type === 'testimonial' ? null : `${urlMap.testimonial}#content`}
                  linkType='Document'
                  element={type === 'testimonial' ? 'span' : 'a'}
                  color={type === 'testimonial' ? 'primary' : 'secondary'}
                  className={classNames({
                    'pointer-events-none': type === 'testimonial',
                    'border-primary': type !== 'testimonial'
                  })}
                >
                  Graduates
                </Button>
              </li>
              <li>
                <Button
                  url={type === 'case_study' ? null : `${urlMap.case_study}#content`}
                  linkType='Document'
                  element={type === 'case_study' ? 'span' : 'a'}
                  color={type === 'case_study' ? 'primary' : 'secondary'}
                  className={classNames({
                    'pointer-events-none': type === 'case_study',
                    'border-primary': type !== 'case_study'
                  })}
                >
                  Businesses
                </Button>
              </li>
            </ul>
          </div>
          <div className='space-y-6 mb-16'>
            <ArticleGrid
              articles={items}
            />
            {featuredItem}
            <ArticleGrid
              articles={secondItems}
            />
          </div>
          <Pagination
            taxonomy={type}
            totalCount={totalCount}
            pageContext={pageContext}
          />
        </Container>
      </section>
      <RenderPositionContentComponents
        slices={slices}
        validComponents={['pre-footer']}
      />
    </>
  )
}

CaseStudyTestimonialIndex.propTypes = {
  slices: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  featuredItem: PropTypes.element,
  type: PropTypes.string.isRequired,
  pageContext: PropTypes.object.isRequired,
  totalCount: PropTypes.number.isRequired

}

export default CaseStudyTestimonialIndex
