import React from 'react'
import PropTypes from 'prop-types'

// Generic
import ArticleGridFeaturedItem from '../../shared/ArticleGrid/ArticleGridFeaturedItem'
import Embed from '../../shared/Embed/Embed'

// Utils
import getTestimonialIntro from '../../../utils/getTestimonialIntro'
import caseStudyDecorator from '../../../utils/caseStudyDecorator'

// Local
import CaseStudyTestimonialIndex from './CaseStudyTestimonialIndex'

const CaseStudyIndex = (props) => {
  const { data, pageContext } = props

  const caseStudies = caseStudyDecorator(data.caseStudies.nodes)

  /**
   * Functiont to get the caption from the blockquote in the case study
   * @param  {Array} slices
   * @return {String|null}
   */
  const getQuoteAuthor = (slices) => {
    if (data.featuredCaseStudies.nodes.length > 0) {
      const firstBlockquote = slices.find((slice) => {
        return slice.slice_type === 'blockquote'
      })

      if (firstBlockquote) {
        return firstBlockquote.primary.testimonial_blockquote_caption.text
      }
    }

    return null
  }

  return (
    <CaseStudyTestimonialIndex
      slices={data.page.data.body}
      items={caseStudies}
      type='case_study'
      pageContext={pageContext}
      totalCount={data.caseStudies.totalCount}
      featuredItem={
        <>
          {data.featuredCaseStudies.nodes.length > 0 && (
            <ArticleGridFeaturedItem
              body={getTestimonialIntro(data.featuredCaseStudies.nodes[0].data)}
              category='Featured'
              date={getQuoteAuthor(data.featuredCaseStudies.nodes[0].data.body)}
              media={(
                <Embed
                  embed={data.featuredCaseStudies.nodes[0].data.embed}
                  title={data.featuredCaseStudies.nodes[0].data.title.text}
                  className='h-full'
                  imageClassName='h-full object-cover'
                />
              )}
              title={data.featuredCaseStudies.nodes[0].data.title.text}
              url={data.featuredCaseStudies.nodes[0].url}
            />
          )}
        </>
      }
    />
  )
}

CaseStudyIndex.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default CaseStudyIndex
