import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import classNames from 'classnames'

// Generic
import Button from '../Button/Button'

// Utils
import paginate from '../../../utils/paginate'
import { urlMap } from '../../../utils/urls'

const Pagination = (props) => {
  const { taxonomy, totalCount, pageContext } = props
  const { currentPage, pageSize, numPages } = pageContext

  const pathName = pageContext.categoryUid && pageContext.categoryUid !== '*' ? `${urlMap[taxonomy]}${pageContext.categoryUid}` : urlMap[taxonomy]

  // Generate pagination info
  const pagination = paginate({
    totalItems: totalCount,
    currentPage,
    pageSize,
    maxPages: 5
  })

  return (
    <div className='flex justify-between items-center mb-10'>
      <div>
        {currentPage !== 1 && (
          <Button
            url={`${pathName}${(currentPage - 1) >= 1 ? '' : `${(currentPage - 1)}`}#content`}
            linkType='Document'
            color='inline'
            iconDirection='left'
            icon
          >
            Prev
          </Button>
        )}
      </div>
      <div className='hidden sm:flex text-center space-x-1'>
        {pagination.pages.map((pageNumber) => {
          return (
            <Link
              key={pageNumber}
              to={`${pathName}${pageNumber === 1 ? '' : `${pageNumber}`}#content`}
              className={classNames('flex justify-center items-center text-primary w-10 h-10 rounded-full hover:bg-primary hover:text-white', {
                'bg-primary bg-opacity-25 pointer-events-none': pageNumber === currentPage
              })}
            >
              <span>{pageNumber}</span>
            </Link>
          )
        })}
      </div>
      <div>
        {currentPage < numPages && (
          <Button
            url={`${pathName}${currentPage + 1}#content`}
            linkType='Document'
            color='inline'
            icon
          >
            Next
          </Button>
        )}
      </div>
    </div>
  )
}

Pagination.propTypes = {
  taxonomy: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default Pagination
